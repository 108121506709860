<template>
  <div>
    <b-form-group
      label="Sekme Başlık"
      label-for="meta_title"
    >
      <b-form-input
        id="meta_title"
        v-model="itemData.translate.meta_title"
        placeholder="Sekme Başlık"
      />
      <b-alert show>
        <div class="alert-body mt-1 font-small-2">
          {{ itemData.item.meta_title }}
        </div>
      </b-alert>
    </b-form-group>
    <b-form-group
      label="Route"
      label-for="route"
    >
      <b-form-input
        id="route"
        v-model="itemData.translate.route"
        placeholder="Route"
        disabled
      />
      <b-alert show>
        <div class="alert-body mt-1 font-small-2">
          {{ itemData.item.route }}
        </div>
      </b-alert>
    </b-form-group>
    <b-form-group
      label="Sayfa Başlık"
      label-for="heading_title"
    >
      <b-form-input
        id="heading_title"
        v-model="itemData.translate.heading_title"
        placeholder="Sayfa Başlık"
      />
      <b-alert show>
        <div class="alert-body mt-1 font-small-2">
          {{ itemData.item.heading_title }}
        </div>
      </b-alert>
    </b-form-group>
    <b-form-group
      label="Sayfa Alt Başlık"
      label-for="heading_text"
    >
      <b-form-input
        id="heading_text"
        v-model="itemData.translate.heading_text"
        placeholder="Sayfa Alt Başlık"
      />
      <b-alert show>
        <div class="alert-body mt-1 font-small-2">
          {{ itemData.item.heading_text }}
        </div>
      </b-alert>
    </b-form-group>
    <b-form-group
      label="Açıklama"
      label-for="meta_desc"
    >
      <b-form-textarea
        id="meta_desc"
        v-model="itemData.translate.meta_desc"
        placeholder="Açıklama"
      />
      <div class="text-muted mt-1">
        Açıklama Uzunluğu: {{ itemData.translate.meta_desc? itemData.translate.meta_desc.length : 0 }} / 155
        <div class="font-small-2 text-info">
          Önerilen Uzunluk: 155 karakter
        </div>
      </div>
      <b-alert show>
        <div class="alert-body mt-1 font-small-2">
          {{ itemData.item.meta_desc }}
        </div>
      </b-alert>
    </b-form-group>
    <b-form-group
      label="Anahtar Kelimeler"
      label-for="meta_keywords"
    >
      <b-form-textarea
        id="meta_keywords"
        v-model="itemData.translate.meta_keywords"
        placeholder="Anahtar Kelimeler"
      />
      <div class="text-muted mt-1">
        Anahtar Kelime: {{ itemData.translate.meta_keywords? itemData.translate.meta_keywords.length : 0 }} / 260
        <div class="font-small-2 text-info">
          Önerilen Uzunluk: 260 karakter
        </div>
      </div>
      <b-alert show>
        <div class="alert-body mt-1 font-small-2">
          {{ itemData.item.meta_keywords }}
        </div>
      </b-alert>
    </b-form-group>
    <b-button
      variant="primary"
      size="sm"
      @click="saveAction"
    >
      <FeatherIcon icon="SaveIcon" />
      Kaydet
    </b-button>
  </div>
</template>
<script>
import {
  BFormGroup, BFormInput, BFormTextarea, BAlert, BButton,
} from 'bootstrap-vue'

export default {
  name: 'Hero',
  components: {
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BAlert,
    BButton,
  },
  props: {
    saveAction: {
      type: Function,
      require: true,
    },
  },
  computed: {
    itemData() {
      return this.$store.getters['translate/dataItem']
    },
  },
}
</script>
