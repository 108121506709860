<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ dataList.title }}</b-card-title>
      </b-card-header>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="dataList.items"
        striped
        fixed
      >
        <template #cell(id_translate)="data">
          <div
            class="text-center"
            :class="data.item.id_translate? 'text-success' : 'text-danger'"
          >
            <FeatherIcon :icon="data.item.id_translate? 'CheckIcon' : 'XIcon'" />
          </div>
        </template>
        <template #cell(control)="data">
          <div class="text-right">
            <b-button
              variant="primary"
              size="sm"
              @click="getData(data.item.id)"
            >
              Çeviri
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>
    <b-modal
      v-model="modalShow"
      centered
      :title="dataList.title"
      ok-only
      size="lg"
      hide-footer
    >
      <component
        :is="dataList.app_component"
        :save-action="submitForm"
      />
    </b-modal>
  </div>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BTable, BButton, BModal,
} from 'bootstrap-vue'
import About from '@/views/Admin/Translate/Forms/About.vue'
import Blog from '@/views/Admin/Translate/Forms/Blog.vue'
import BlogCategories from '@/views/Admin/Translate/Forms/BlogCategories.vue'
import Carousel from '@/views/Admin/Translate/Forms/Carousel.vue'
import Components from '@/views/Admin/Translate/Forms/Components.vue'
import Configs from '@/views/Admin/Translate/Forms/Configs.vue'
import Contacts from '@/views/Admin/Translate/Forms/Contacts.vue'
import Contents from '@/views/Admin/Translate/Forms/Contents.vue'
import History from '@/views/Admin/Translate/Forms/History.vue'
import Menus from '@/views/Admin/Translate/Forms/Menus.vue'
import News from '@/views/Admin/Translate/Forms/News.vue'
import Fairs from '@/views/Admin/Translate/Forms/Fairs.vue'
import Certificates from '@/views/Admin/Translate/Forms/Certificates.vue'
import Whatsapp from '@/views/Admin/Translate/Forms/Whatsapp.vue'
import HrPositions from '@/views/Admin/Translate/Forms/HrPositions.vue'
import ImageGallery from '@/views/Admin/Translate/Forms/ImageGallery.vue'
import VideoGallery from '@/views/Admin/Translate/Forms/VideoGallery.vue'
import Products from '@/views/Admin/Translate/Forms/Products.vue'
import ProductCategories from '@/views/Admin/Translate/Forms/ProductCategories.vue'
import Services from '@/views/Admin/Translate/Forms/Services.vue'
import ServiceCategories from '@/views/Admin/Translate/Forms/ServiceCategories.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BButton,
    BModal,
    About,
    Blog,
    BlogCategories,
    Carousel,
    Components,
    Configs,
    Contacts,
    Contents,
    History,
    Menus,
    News,
    Fairs,
    Certificates,
    Whatsapp,
    HrPositions,
    ImageGallery,
    VideoGallery,
    Products,
    ProductCategories,
    Services,
    ServiceCategories,
  },
  data() {
    return {
      fields: [
        {
          key: 'title',
          label: 'BAŞLIK',
        },
        {
          key: 'id_translate',
          label: 'DURUM',
          thClass: 'text-center width-150',
        },
        {
          key: 'control',
          label: '',
          thStyle: { width: '200px' },
        },
      ],
      modalShow: false,
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['translate/dataList']
    },
    saveData() {
      return this.$store.getters['translate/dataSaveStatus']
    },
    modalData() {
      return this.$store.getters['translate/dataItem']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
      this.modalShow = false
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    submitForm() {
      this.modalData.language = this.$route.params.language
      this.$store.dispatch('translate/saveData', this.modalData)
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      }, {
        position: 'bottom-center',
      })
    },
    getData(id) {
      this.$store.dispatch('translate/getDataItem', {
        id: this.$route.params.id,
        language: this.$route.params.language,
        itemid: id,
      })
        .then(res => {
          if (res) {
            this.modalShow = true
          }
        })
    },
    getDataList() {
      this.$store.dispatch('translate/getDataList', {
        language: this.$route.params.language,
        id: this.$route.params.id,
      })
    },
  },
}
</script>
